///
// Masonry Items
///
.c-masonry-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &.c-masonry-items\@1 {
        // Medium Desktops
        @include media(md) {
            width: 50%;
        }
    }
}

///
// Masonry Item
///
.c-masonry-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 256px;
    padding: rem(15px);
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .c-section--masonry & {
        // Medium Desktops
        @include media(md) {
            opacity: 0.5;
            transform: scale(0.5);
            transition: opacity 0.35s, transform 1.5s;
        }
    }

    .c-masonry-items\@1 & {
        // Medium Desktops
        @include media(md) {
            width: 100%;
        }
    }

    .c-masonry-items\@2 & {
        // Medium Desktops
        @include media(md) {
            width: 50%;
            min-height: 521px;
        }
    }

    .c-masonry-items\@3 & {
        // Medium Desktops
        @include media(md) {
            width: 33.3333333333%;
            min-height: 521px;
            border-bottom: 3px solid white;
        }
    }

    .page-template-template-what-we-do & {
        // Medium Desktops
        @include media(md) {
            min-height: 470px;
        }
    }

    &.is-active {
        // Medium Desktops
        @include media(md) {
            opacity: 1;
            transform: scale(1);
        }
    }
}

///
// Mobile Hide
///
.c-masonry-item--3,
.c-masonry-item--5,
.c-masonry-item--6,
.c-masonry-item--7,
.c-masonry-item--9 {
    .c-section--masonry & {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }
    }
}

///
// Item with Border
///
.c-masonry-item--3,
.c-masonry-item--4 {
    .c-masonry-items\@2 & {
        // Medium Desktops
        @include media(md) {
            border-bottom: 6px solid white;
        }
    }
}

.c-masonry-item--1 {
    .c-masonry-items\@1 & {
        border-bottom: 3px solid white;

        // Medium Desktops
        @include media(md) {
            min-height: 736px;
        }
    }

    .c-masonry-items\@2 & {
        // Medium Desktops
        @include media(md) {
            border-right: 3px solid white;
            border-bottom: 3px solid white;
        }
    }
}

.c-masonry-item--2 {
    .c-masonry-items\@1 & {
        border-bottom: 3px solid white;

        // Medium Desktops
        @include media(md) {
            min-height: 521px;
        }
    }

    .c-masonry-items\@2 & {
        // Medium Desktops
        @include media(md) {
            border-left: 3px solid white;
            border-bottom: 3px solid white;
        }
    }

    .c-masonry-items\@3 & {
        // Medium Desktops
        @include media(md) {
            border-left: 3px solid white;
            border-right: 3px solid white;
        }
    }
}

.c-masonry-item--3 {
    .c-masonry-items\@2 & {
        // Medium Desktops
        @include media(md) {
            border-right: 3px solid white;
            border-top: 3px solid white;
        }
    }
}

.c-masonry-item--4 {
    .c-masonry-items\@2 & {
        // Medium Desktops
        @include media(md) {
            border-left: 3px solid white;
            border-top: 3px solid white;
        }
    }
}

///
// 4x3 Ratio
///
.c-masonry-item--\@4x3 {
    background-image: url('/app/uploads/2017/02/w630x511.png');
}

///
// 16x9 Ratio
///
.c-masonry-item--\@16x9 {
    background-image: url('/app/uploads/2017/02/w630x256.png');
}

///
// Body
///
.c-masonry-item__body {
    display: block;
    padding: rem(30px);
    color: white;
    background-color: rgba(black, 0.75);
    border: 1px solid white;
    cursor: pointer;
    text-decoration: none;

    // Medium Desktops
    @include media(md) {
        padding-left: rem(60px);
        padding-right: rem(60px);
    }

    h4 {
        color: inherit;
    }
}

///
// Title
///
.c-masonry-item__title {
    display: inline-block;
    position: relative;
    padding: rem(5px) rem(15px);
    font-size: rem(24px);
    font-family: $font-roboto-bold;
    color: white;
    text-transform: uppercase;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 2px solid transparent;
    }

    &:before {
        top: 0;
        left: 0;
    }

    &:after {
        bottom: 0;
        right: 0;
    }

    .c-masonry-item:hover & {
        &:before,
        &:after {
            width: 100%;
            height: 100%;
            transition: width 0.30s ease-out, height 0.30s ease-out 0.30s;
        }

        &:before {
            border-top-color: white;
            border-right-color: white;
        }

        &:after {
            border-bottom-color: white;
            border-left-color: white;
        }
    }
}

///
// Link
///
.c-masonry-item__link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    //z-index: z('last');
    width: 100%;
    height: 100%;
    text-decoration: none;
    transition: background-color 0.75s;
    cursor: pointer;

    .c-masonry-item:hover & {
        background-color: rgba(color('red'), 0.75);
    }
}
