///
// Popup
///
.c-form--popup {
    display: none;
    position: relative;
    width: 100%;
    max-width: 680px;
    margin: 0;
    padding: rem(30px);
    color: white;
    background-color: color('red');

    h3 {
        margin-bottom: 0;
        color: inherit;
    }

    input,
    select {
        color: white;
        background-color: transparent;
    }

    #mce-MMERGE3 {
        background: transparent !important ;
        color: #fff !important;

        option {
            background: #fff !important;
            color: #000 !important;
        }
    }

    ::-webkit-input-placeholder {
        font-size: rem(14px);
        color: inherit;
        text-transform: uppercase;
    }

    ::-moz-placeholder {
        font-size: rem(14px);
        color: inherit;
        text-transform: uppercase;
    }

    :-ms-input-placeholder {
        font-size: rem(14px);
        color: inherit;
        text-transform: uppercase;
    }

    :-moz-placeholder {
        font-size: rem(14px);
        color: inherit;
        text-transform: uppercase;
    }
}

///
// Left & Right
///
.c-form__group--left,
.c-form__group--right {
    // Medium Desktops
    @include media(md) {
        width: 49%;
    }
}

///
// Left
///
.c-form__group--left {
    // Medium Desktops
    @include media(md) {
        float: left;
    }
}

///
// Right
///
.c-form__group--right {
    // Medium Desktops
    @include media(md) {
        float: right;
    }
}

///
// Clear
///
.c-form__group--clear {
    width: 100%;
    clear: both;
}
