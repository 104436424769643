.c-footer {
    padding-top: rem(30px);
    padding-bottom: rem(30px);

    // Medium Max Desktops
    @include media-max(md) {
        text-align: center;
    }

    // Medium Desktops
    @include media(md) {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
    }

    .c-info {
        margin-bottom: rem(15px);
        line-height: 2;
    }

    .c-info--address {
        font-size: rem(12px);
    }

    .c-social {
        text-decoration: none;
        transition: transform 0.35s;
    }

    .c-social + .c-social {
        margin-left: rem(5px);
    }

    .c-btn + .c-btn {
        margin-top: rem(10px);
    }

    .fa {
        width: 32px;
        height: 32px;
        padding-top: rem(7px);
        color: white;
        background-color: black;
        transition: background-color 0.35s;

        // Medium Desktops
        @include media(md) {
            width: 40px;
            height: 40px;
            padding-top: rem(10px);
            font-size: rem(21px);
        }
    }

    .fa-facebook:hover {
        background-color: social('facebook');
    }

    .fa-google-plus:hover {
        background-color: social('googleplus');
    }

    .fa-linkedin:hover {
        background-color: social('linkedin');
    }

    .fa-instagram:hover {
        background-color: social('instagram');
    }

    .fa-twitter:hover {
        background-color: social('twitter');
    }
    hr {
      margin-top: rem(30px);
    }
}

///
// Blocks
///
.c-footer__blocks {
    @include make-row;
    padding-bottom: rem(50px);
}

///
// Block
///
.c-footer__block {
    width: 100%;
    padding-left: rem(15px);
    padding-right: rem(15px);

    // Medium Desktops
    @include media(md) {
        float: left;
        width: 20%;
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    li {
        margin-bottom: rem(10px);
    }

    a:not([class]) {
        font-size: rem(12px);
        color: $base-font-color;
        text-decoration: none;
        text-transform: none;

        @include link-states() {
            color: color('red');
        }
    }

    .c-btn {
        // Medium Desktops
        @include media(md) {
            font-size: rem(10px);
            position: absolute;
            margin-top: 13px;
        }
        // Large Desktops
        @include media(lg) {
            font-size: rem(12px);
            position: absolute;
            margin-top: 13px;
        }
        // extra Large Desktops
        @include media(xl){

        }
    }
}

.c-footer__block:nth-child(1) {
    .menu-proposal-request a {
        padding: rem(5px) rem(20px);
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        background-color: color('red');
        transition: background-color 0.35s;
        @include media(md){
          font-size: rem(10px);
        }
        @include media(lg){
            font-size: rem(12px);
        }
        @include media(xl){
            font-size: rem(13px);
        }
        @include link-states() {
            color: white;
            background-color: black;
        }
    }
}

///
// Blocks to hide
///
.c-footer__block:nth-child(1),
.c-footer__block:nth-child(2),
.c-footer__block:nth-child(3),
.c-footer__block:nth-child(4) {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }
}

///
// Block Title
///
.c-footer__block__title {
    margin-bottom: rem(10px);
    font-size: rem(14px);
    color: $base-font-color;
}

///
// Logo
///
.c-footer__logo {
    display: block;
    max-width: 290px;
    max-height: 87px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(20px);
}

///
// Copyright
///
.c-footer__copypre {
    font-size: rem(12px);
    margin-top: rem(14px);
    color: color('red', 'default');
    margin-bottom: neg(rem(12px)) !important;
    line-height: 1.8em;
    font-style: italic;
    text-align: center;
}

.c-footer__copyright {
    margin-top: rem(10px);
    font-size: rem(12px);

    a {
        color: inherit;
        text-decoration: none;

        @include link-states() {
            color: color('red');
        }
    }
}
