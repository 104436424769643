body {
    margin: 0;
    font-size: $base-font-size;
    font-family: $font-roboto-light;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Large Desktops
    @include media(lg) {
        padding-left: rem(30px);
        padding-right: rem(30px);
    }
}

hr {
    height: 1px;
    margin-top: rem(30px);
    margin-bottom: rem(30px);
    background-color: color('grey', 'border');
    border: 0;

    // Medium Desktops
    @include media(md) {
        margin-top: rem(60px);
        margin-bottom: rem(60px);
    }
}
