///
// Nav Left
///
.c-nav--left {
    .c-nav__item:not(:first-child) {
        // Medium Desktops
        @include media(md) {
            margin-left: rem(70px) !important;
        }

        // Large Desktops
        @include media(lg) {
            margin-left: rem(40px) !important;
        }
    }

    .c-nav__dropdown {
        .c-nav__item {
            // Medium Desktops
            @include media(md) {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
}

///
// Gravity Forms
///
.gform_wrapper {
    ul.gfield_checkbox {
        li {
            input,
            label {
                display: inline-block !important;
                vertical-align: middle !important;
            }

            input[type="checkbox"] {
                width: 20px !important;
                height: 20px !important;
            }

            input[type="checkbox"]:checked {
                background-color: color('red') !important;

                + label {
                    font-weight: 500 !important;
                }
            }
        }
    }
}
