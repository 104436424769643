input,
select,
textarea {
    width: 100%;
    margin-bottom: rem(15px);
    padding: rem(10px);
    font-size: rem(14px);
    color: $base-font-color;
    border: 1px solid color('grey', 'medium');
    border-radius: 0;
    appearance: none;
    outline: none;
}

select {
    text-transform: uppercase;
}

input[type="submit"] {
    width: auto;
    margin-bottom: 0;
    padding: rem(10px) rem(20px);
    text-transform: uppercase;
}
