.c-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z('header');
    transition: background-color 1s;

    &:hover {
        // Medium Desktops
        @include media(md) {
            background-color: rgba(black, 0.60);
        }
    }

    // Large Desktops
    @include media(lg) {
        left: 30px;
        right: 30px;
    }

    .logged-in & {
        // Medium Desktops
        @include media(md) {
            top: 32px;
        }
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            background-color: rgba(black, 0.60);
        }
    }
}
