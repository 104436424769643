///
// Nav Left
///
.c-nav--left {
    // Medium Desktops
    @include media(md) {
        float: left;
    }
}

///
// Nav Right
///
.c-nav--right {
    // Medium Desktops
    @include media(md) {
        float: right;
    }

    .c-nav__item:not(:first-child) {
        // Medium Desktops
        @include media(md) {
            margin-right: rem(30px);
        }

        // Large Desktops
        @include media(lg) {
            margin-right: 0;
        }
    }
}

///
// Mobile
///
.c-nav--mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: z('mobile-nav');
    width: 100%;
    height: 100%;
    padding: rem(100px) rem(15px);
    text-align: center;
    background-color: rgba(black, 0.90);
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transition: all 0.65s;

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    &.is-open {
        opacity: 1;
        visibility: visible;
    }

    a {
        color: white;
        text-decoration: none;
    }

    .c-nav__item + .c-nav__item {
        margin-top: rem(30px);
    }
}

///
// Nav List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

///
// Nav Item
///
.c-nav__item {
    position: relative;
    list-style: none;

    a {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.c-nav--primary {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }

    .c-nav__item {
        // Medium Desktops
        @include media(md) {
            display: inline-block;
            padding-top: rem(60px);
            padding-bottom: rem(30px);
            vertical-align: top;
        }

        .is-scrolling & {
            // Medium Desktops
            @include media(md) {
                padding-top: rem(40px);
            }
        }
    }

    .c-nav__item + .c-nav__item {
        // Medium Desktops
        @include media(md) {
            margin-left: rem(40px);
        }

        // Large Desktops
        @include media(lg) {
            margin-left: rem(40px);
        }
    }

    a {
        // Medium Desktops
        @include media(md) {
            font-size: rem(15px);
        }
    }
}

///
// Dropdown
///
.c-nav__dropdown {
    display: none;
    margin-top: rem(20px);
    margin-bottom: 0;
    padding-left: 0;

    // Medium Desktops
    @include media(md) {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 150px;
        margin-top: 0;
        padding: rem(10px) rem(15px);
        background-color: rgba(black, 0.60);
    }

    .c-nav__item {
        display: block;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1;

        .is-scrolling & {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .c-nav__item:hover & {
        // Medium Desktops
        @include media(md) {
            display: block;
        }
    }

    .c-nav__item + .c-nav__item {
        margin-left: 0;

        // Medium Desktops
        @include media(md) {
            margin-top: rem(15px);
        }
    }

    .is-expanded & {
        display: block;
    }
}
