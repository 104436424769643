.c-logo {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 342px;
    max-height: 96px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.75s;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(15px);
    }

    // Medium Desktops
    @include media(md) {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        max-width: 322px;
        max-height: 76px;
    }

    // Large Desktops
    @include media(lg) {
        max-width: 342px;
        max-height: 96px;
    }

    .is-scrolling & {
        // Medium Max Desktops
        @include media-max(md) {
            opacity: 0;
            transform: translateY(-100%);
        }

        // Medium Desktops
        @include media(md) {
            top: 10px;
            max-width: 240px;
            max-height: 70px;
        }
    }
}
