.c-btn {
    display: inline-block;
    padding: rem(5px) rem(20px);
    font-size: rem(14px);
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.35s;
}

///
// Red
///
.c-btn--red {
    background-color: color('red');

    @include link-states() {
        background-color: black;
    }
}

.c-btn--white {
    border: 1px solid white;
}
