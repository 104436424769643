.c-locations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem(30px);

    // Medium Max Desktops
    @include media-max(md) {
        flex-direction: column;
    }

    // Medium Desktops
    @include media(md) {
        flex-wrap: nowrap;
    }
}

///
// Location
///
.c-location {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    box-shadow: inset 0 0 0 2px white;

    // Large Desktops
    @include media(lg) {
        flex-basis: 15%;
    }

    /*&:hover {
        &:before,
        &:after {
            width: 100%;
            height: 100%;
        }

        &:before {
            border-top-color: color('red');
            border-right-color: color('red');
            transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
        }

        &:after {
            border-bottom-color: color('red');
            border-left-color: color('red');
            transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s;
        }
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 2px solid transparent;
    }

    &:before {
        top: 0;
        left: 0;
    }

    &:after {
        bottom: 0;
        right: 0;
    }*/
}

.c-location + .c-location {
    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(30px);
    }
}

///
// Link
///
.c-location__link {
    display: block;
    position: relative;
    z-index: z('location-link');
    width: 100%;
    padding: rem(10px) rem(15px);
    color: inherit;
    text-decoration: none;
}
