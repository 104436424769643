h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: rem(20px);
    font-weight: 500;
}

h2,
h4,
h5,
h6 {
    font-family: $font-roboto-medium;
}

h1,
h2,
h4 {
    color: color('red');
}

h3,
h5,
h6 {
    color: color('grey', 'dark');
}

h1 {
    @include font-size('h1');
    font-family: $font-roboto-light;
}

h2 {
    @include font-size('h2');
}

h3 {
    @include font-size('h3');
    font-family: $font-roboto-bold;
}

h4 {
    @include font-size('h4');
}

h5 {
    @include font-size('h5');
}

h6 {
    @include font-size('h6');
}

p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 2.4;
}

p:not(:last-child) {
    margin-bottom: rem(30px);
}

a:not([class]) {
    color: color('red');
}

strong {
    font-family: $font-roboto-medium;
    font-weight: 500;
}
