.u-animate-delay\@1 {
    transition-delay: 0.5s;
}

.u-animate-delay\@2 {
    transition-delay: 1s;
}

.u-animate-delay\@3 {
    transition-delay: 1.5s;
}

.u-animate-delay\@4 {
    transition-delay: 2s;
}

.u-animate-delay\@5 {
    transition-delay: 2.5s;
}

.u-animate-bounce {
    animation: bounce 1.5s infinite;
}

.u-animate-fadeinup {
    animation: fadeInUp 2s;
}
