///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }

@font-face {
    font-family: 'robotobold';
    src: url('../../dist/fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold_italic';
    src: url('../../dist/fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoitalic';
    src: url('../../dist/fonts/roboto-italic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotolight';
    src: url('../../dist/fonts/roboto-light-webfont.woff2') format('woff2'),
         url('../../dist/fonts/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotolight_italic';
    src: url('../../dist/fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium';
    src: url('../../dist/fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../../dist/fonts/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium_italic';
    src: url('../../dist/fonts/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url('../../dist/fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../../dist/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
