.c-hero {
    position: relative;
    padding-top: rem(99px);
    padding-bottom: rem(99px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 3px solid white;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(198px);
        padding-bottom: rem(198px);
    }
}

///
// Home
///
.c-hero--home {
    padding-top: rem(235px);
    padding-bottom: rem(235px);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(470px);
        padding-bottom: rem(470px);
    }
}

///
// Content
///
.c-hero__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(15px);
    padding-right: rem(15px);
    padding-bottom: rem(30px);
    color: white;
    text-align: center;
    transition: opacity 0.35s, transform 2s;

    // Medium Desktops
    @include media(md) {
        transform: translateY(-50%);
    }

    h1 {
        margin-bottom: 0;
        color: inherit;
    }

    em {
        font-family: $font-roboto-medium-italic;
        font-style: normal;
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            transform: translateY(0);
        }
    }

    .js-scroll-down {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        text-decoration: none;

        &:before {
            content: '\f107';
            font-size: rem(42px);
            font-family: 'FontAwesome';
            color: white;
        }
    }
}
