.c-aircrafts {
    margin-left: neg(rem(15px));
    margin-right: neg(rem(15px));

    // Medium Desktops
    @include media(md) {
        display: flex;
        flex-wrap: wrap;
    }
}

///
// Aircraft
///
.c-aircraft {
    .c-aircrafts & {
        width: 100%;
        margin-bottom: rem(30px);
        padding-left: rem(15px);
        padding-right: rem(15px);
        color: white;

        // Medium Desktops
        @include media(md) {
            display: flex;
            width: 25%;
        }

        &:hover {
            .c-aircraft__serial,
            .c-btn {
                // Medium Desktops
                @include media(md) {
                    opacity: 1;
                    transform: translateY(-30%);
                    transition-delay: 0.1s;
                }
            }
        }
    }

    .c-aircrafts--wanted,
    .c-aircrafts--acquired & {
        position: relative;
        width: 100%;
        margin-bottom: rem(30px);
        padding-left: rem(15px);
        padding-right: rem(15px);

        // Medium Desktops
        @include media(md) {
            display: flex;
            width: 25%;
        }
    }
}

///
// Single
///
.c-aircraft--single {
    position: relative;

    .gallery {
        margin-bottom: rem(30px);
    }
}

///
// Sold
///
.c-aircraft--sold {
    pointer-events: none;
    cursor: default;

    .c-aircraft__inner:before {
        content: 'Sold';
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        opacity: 1;
        font-size: rem(26px);
        text-transform: uppercase;
        background-color: rgba(black, 0.55);
    }
}



///
// Sale Pending
///
.c-aircraft--sale--pending {
    pointer-events: none;
    cursor: default;

    .c-aircraft__inner:before {
        content: 'Sale Pending';
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        opacity: 1;
        font-size: rem(20px);
        text-transform: uppercase;
        background-color: rgba(black, 0.15) !important;
    }
}

///
// Off Market
///
.c-aircraft--off--market {
    pointer-events: none;
    cursor: default;

    .c-aircraft__inner:before {
        content: 'Off Market';
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        opacity: 1;
        font-size: rem(20px);
        text-transform: uppercase;
        background-color: rgba(black, 0.15) !important;

    }
}

///
// Aquired Completed
///
.c-aircraft--acquired {
    pointer-events: none;
    cursor: default;

    .c-aircraft__inner:before {
        content: 'Acquisition Completed';
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        opacity: 1;
        font-size: rem(20px);
        text-transform: uppercase;
        background-color: rgba(black, 0.55);
    }
}

///
// Inner
///
.c-aircraft__inner {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(color('red'), 0.75);
        opacity: 0;
        transition: top opacity 0.3s;

        .c-aircraft:not(.c-aircraft--single):hover & {
            // Medium Desktops
            @include media(md) {
                top: 0;
                opacity: 1;
            }
        }
    }
}

///
// Body
///
.c-aircraft__body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: rem(10px) rem(15px);

    .c-btn {
        font-size: rem(12px);
        padding: rem(3px) rem(15px);
        opacity: 0;
        transform: translateY(100%);
        transition: all 0.35s;
    }
}

///
// Image
///
.c-aircraft__image {
    position: relative;
    z-index: z('last');

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background: linear-gradient(to bottom, transparent 0%, rgba(black, 0.45) 100%);
    }

    .c-aircraft--wanted & {
        &:before {
            content: 'Sample';
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            font-size: rem(26px);
            text-transform: uppercase;
            background-color: rgba(black, 0.55);
        }
    }

    img {
        width: 100%;
    }
}

///
// Title
///
.c-aircraft__title {
    .c-aircrafts & {
        margin-bottom: 0;
        font-size: rem(14px);
        font-family: $font-roboto-bold;
        color: inherit;
        transform: translateY(200%);
        transition: all 0.35s;
    }

    .c-aircrafts--wanted & {
        transform: translateY(0);
    }

    .c-aircraft:not(.c-aircraft--single):hover & {
        // Medium Desktops
        @include media(md) {
            transform: translateY(-100%);
        }
    }
}

///
// Info
///
.c-aircraft__info {
    display: none;
    width: 100%;
    max-width: 690px;
    margin: 0;
    padding: rem($base-gutter);
    background-color: color('grey', 'dark');

    * {
        color: white;
    }

    h6 {
        margin-bottom: rem(10px);
        text-transform: uppercase;
    }

    ol:not([class]),
    ul:not([class]) {
        margin-top: 0;
        margin-bottom: rem($base-gutter);
        padding-left: rem(halve($base-gutter));
        list-style: none;
    }

    li:not([class]) {
        position: relative;
        padding-left: rem(halve($base-gutter));

        &:before {
            content: '\2022';
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    li + li {
        margin-top: rem(10px);
    }

    .c-btn {
        margin-top: rem(10px);
        outline: none;
    }
}

///
// Serial Number
///
.c-aircraft__serial {
    font-size: rem(18px);
    font-family: $font-roboto-light;

    .c-aircrafts & {
        display: block;
        font-size: rem(12px);
        opacity: 0;
        transform: translateY(50%);
        transition: all 0.35s;
    }

    .c-aircraft:not(.c-aircraft--single):hover & {
        // Medium Desktops
        @include media(md) {
            transform: translateY(-90%);
        }
    }
}

///
// Link
///
.c-aircraft__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

///
// Slider
///
.c-aircraft__slider {
    width: 100%;
    max-width: 683px;
    margin: 0 auto rem(15px);
    padding: 0;
    list-style: none;

    // Medium Desktops
    @include media(md) {
        position: relative;
        top: neg(285px);
        margin-bottom: neg(rem(270px));
    }
}

///
// Details
///
.c-aircraft__details {
    position: relative;
    margin-top: rem(30px);
    margin-bottom: rem(30px);
    padding-top: rem(15px);
    padding-bottom: rem(15px);
    border-top: 1px solid color('grey', 'dark');
    border-bottom: 1px solid color('grey', 'dark');
    @include make-row;

    // Medium Max Desktops
    @include media-max(md) {
        text-align: center;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: neg(1px);
        width: 15px;
        height: 85px;
        background-color: white;
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }
}

///
// Detail
///
.c-aircraft__detail {
    width: 100%;
    padding-left: rem(15px);
    padding-right: rem(15px);
    text-transform: uppercase;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto;

    // Medium Max Desktops
    @include media-max(md) {
        padding-top: rem(50px);
    }

    // Medium Desktops
    @include media(md) {
        float: left;
        width: 20%;
        padding-left: rem(65px);
        background-position: 15px center;
    }

    h6 {
        margin-bottom: 0;
    }
}

.c-aircraft__detail + .c-aircraft__detail {
    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(15px);
    }
}

.c-aircraft__detail--total-time {
    background-image: url('../../dist/images/icon-clock.png');
}

.c-aircraft__detail--cycles {
    background-image: url('../../dist/images/icon-cycle.png');
}

.c-aircraft__detail--passengers {
    background-image: url('../../dist/images/icon-group.png');
}

.c-aircraft__detail--programs {
    background-image: url('../../dist/images/icon-plane.png');
}

.c-aircraft__detail--high-speed-data{
    background-image: url('../../dist/images/icon-wifi.png');
}

///
// Disclaimer
///
.c-aircraft__disclaimer {
    margin-bottom: 0;
    font-size: rem(12px);
    font-family: $font-roboto-light-italic;
}
