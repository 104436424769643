.o-col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: rem(halve($base-gutter));
    padding-left: rem(halve($base-gutter));

    @if $flexbox != true {
        float: left;
    } @else {
        display: flex;
    }
}

///
// Inner
///
.o-col__inner {
    width: 100%;
}

///
// Create generic columns
///
@for $i from 1 through $base-columns {
    .o-col--#{$i} {
        width: percentage($i / $base-columns);
    }
}

///
// Create breakpoint based columns
///
@each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint) {
        @for $i from 1 through $base-columns {
            .o-col--#{$i}\@#{$breakpoint} {
                width: percentage($i / $base-columns);
            }
        }
    }
}

///
// Column Offsets
///
@if $column-offsets {
    ///
    // Create generic column offsets
    ///
    @for $i from 1 through $base-columns {
        .o-col--offset-#{$i} {
            margin-left: percentage($i / $base-columns);
        }
    }

    ///
    // Create breakpoint based column offsets
    ///
    @each $breakpoint in map-keys($breakpoints) {
        @include media($breakpoint) {
            @for $i from 1 through $base-columns {
                .o-col--offset-#{$i}\@#{$breakpoint} {
                    margin-left: percentage($i / $base-columns);
                }
            }
        }
    }
}
