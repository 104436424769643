.c-team-members {
    @include make-row;
}

///
// Team Member
///
.c-team-member {
    width: 100%;
}

.c-team-member + .c-team-member {
    margin-top: rem(30px);
}

///
// Image & Bio
///
.c-team-member__image,
.c-team-member__bio {
    float: left;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: rem(15px);
    padding-left: rem(15px);
}

///
// Image
///
.c-team-member__image {
    // Medium Desktops
    @include media(md) {
        width: 25%;
    }

    img {
        // Medium Max Desktops
        @include media-max(md) {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

///
// Bio
///
.c-team-member__bio {
    // Medium Desktops
    @include media(md) {
        width: 75%;
    }
}
