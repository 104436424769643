///
// Breakpoints
///
$breakpoints: (
    xs: 480px,  // Extra Small devices (landscape phones, 480px and up)
    sm: 768px,  // Small devices (tablets, 768px and up)
    md: 1024px, // Medium devices (desktops, 1024px and up)
    lg: 1200px, // Large devices (desktops, 1200px and up)
    xl: 1500px  // Extra Lage devices (desktops, 1500px and up)
);

///
// Base global defaults
///
$base-columns:      12;
$base-gutter:       30px;
$base-font-size:    16px;
$base-font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-line-height:  1.5;
$base-font-color:   #333333;

///
// Font sizes
///
$font-sizes: (
    'h1': (
        'initial':  30px,
        'sm':       36px
    ),
    'h2': (
        'initial':  22px,
        'sm':       28px
    ),
    'h3': (
        'initial':  20px,
        'sm':       26px
    ),
    'h4': (
        'initial':  20px,
        'sm':       26px
    ),
    'h5':           24px,
    'h6':           18px
);

///
// Enable flexbox grid
///
$flexbox: true;

///
// Column offsets
//
// Will enable the creation of column offset classes
///
$column-offsets: false;

///
// Debug mode
//
// In order to use the debug mode you need to set the below variable to true
// and you also need to set the THEME_DEBUG constant in the wp-config to true
///
$debug: false;

///
// Colors Map for @function color();
///
$colors: (
    'grey': (
        'default':      #efefef,
        'medium':       #e5e5e5,
        'border':       #cccccc,
        'dark':         #767275
    ),
    'bigrigmedia': (
        'orange':       #f6921e,
        'grey':         #4d4d4d
    ),
    'red': (
        'default':      #26abe2
    )
);

///
// Alerts Map for @function alert();
///
$alerts: (
    'success': (
        'default':  #dff0d8,
        'message':  #3c763d,
        'border':   #d6e9c6
    ),
    'info': (
        'default':  #d9edf7,
        'message':  #31708f,
        'border':   #bce8f1
    ),
    'warning': (
        'default':  #fcf8e3,
        'message':  #8a6d3b,
        'border':   #faf2cc
    ),
    'error': (
        'default':  #f2dede,
        'message':  #a94442,
        'border':   #ebcccc
    )
);

///
// Social Networks Map for @function social();
///
$social-networks: (
    facebook:   #3b5998,
    twitter:    #00aced,
    googleplus: #dd4b39,
    linkedin:   #007bb6,
    youtube:    #bb0000,
    instagram:  #517fa4,
    pinterest:  #cb2027,
    flickr:     #ff0084,
    tumblr:     #32506d,
    foursquare: #0072b1,
    dribble:    #ea4c89,
    vine:       #00bf8f
);

///
// Z-index Map for @function z();
///
$z-index: (
    debug: 1,
    header: 1000,
    last: -1,
    location-link: 100,
    mobile-nav: 1100,
    nav-toggle: 2000
);

///
// Fonts
///
$font-roboto-light: 'robotolight';
$font-roboto-light-italic: 'robotolight_italic';
$font-roboto-medium: 'robotomedium';
$font-roboto-medium-italic: 'robotomedium_italic';
$font-roboto-bold: 'robotobold';

///
// Animations
///
@keyframes bounce {
    0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(15px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    100% {
        opacity: 1;
        transform: translateY(-50%);
    }
}
