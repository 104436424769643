.c-section {
    padding-top: rem(30px);
    padding-bottom: rem(30px);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
    }
}

///
// Masonry
///
.c-section--masonry {
    padding-top: 0;
    padding-bottom: 0;

    .o-col {
        padding-left: 0;
        padding-right: 0;

        // Medium Max Desktops
        @include media-max(md) {
            min-height: auto;
        }
    }

    .o-col--4\@md:nth-child(1),
    .o-col--4\@md:nth-child(2) {
        // Medium Desktops
        @include media(md) {
            border-right: 3px solid white;
            border-bottom: 3px solid white;
        }
    }

    .o-col--4\@md:nth-child(3),
    .o-col--12\@md {
        // Medium Desktops
        @include media(md) {
            border-bottom: 3px solid white;
        }
    }

    .o-col--4\@md:nth-child(4),
    .o-col--4\@md:nth-child(5) {
        // Medium Desktops
        @include media(md) {
            border-right: 3px solid white;
        }
    }

    .o-col--6\@md:not(:last-child) {
        // Medium Desktops
        @include media(md) {
            border-right: 3px solid white;
        }
    }
}

///
// About
///
.c-section--about {
    background-image: url('../../dist/images/bg-intro.png');
    background-position: center;
    background-repeat: no-repeat;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(120px);
        padding-bottom: rem(120px);
    }
}

///
// Locations
///
.c-section--locations {
    color: white;
    background-color: black;
    background-image: url('/app/uploads/2017/02/bg-destinations-mobile.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(155px);
        padding-bottom: rem(155px);
        background-image: url('/app/uploads/2017/02/bg-destinations.jpg');
    }
}

///
// Main
///
.c-section--main {
    .page-template-template-secondary-detail & {
        padding-top: 0;
        padding-bottom: 0;

        .o-col__inner:first-child {
            // Medium Desktops
            @include media(md) {
                max-width: 570px;
                margin-left: auto;
                margin-right: 0;
            }
        }

        .o-col--6\@md:first-child {
            padding-top: rem(30px);
            padding-bottom: rem(30px);

            // Medium Desktops
            @include media(md) {
                padding-top: rem(60px);
                padding-bottom: rem(60px);
            }
        }
    }
}
